<template>
  <b-card>
    <loading
      :active="isLoading"
      loader="bars"
      color="#7367f0"
      :is-full-page="fullPage"
    />
    <!-- search input -->
    <div class="custom-search d-flex justify-content-start">
      <!-- <vue-excel-xlsx
        :data="exportData"
        :columns="exportColumns"
        :file-name="'servolution'"
        :file-type="'xlsx'"
        :sheet-name="'sheetname'"
        class="btn btn-primary"
      >
        Export
      </vue-excel-xlsx> -->
    </div>
    <div class="custom-search">
      <b-row>
        <b-col md="4">
          <b-form-group>
            <label>NIJ</label>
            <b-form-input
              v-model="nij"
              placeholder="Search"
              type="text"
              class="d-inline-block"
            />
          </b-form-group>
        </b-col>
        <b-col md="4">
          <b-form-group>
            <label>Name</label>
            <b-form-input
              v-model="name"
              placeholder="Search"
              type="text"
              class="d-inline-block"
            />
          </b-form-group>
        </b-col>
        <b-col md="4">
          <b-form-group>
            <label>Gender</label>
            <v-select
              v-model="gender"
              placeholder="Choose Gender"
              label="name"
              :options="genderOptions"
            />
          </b-form-group>
        </b-col>
        <b-col md="4">
          <b-form-group>
            <label>CG Category</label>
            <v-select
              v-model="cgCategory"
              placeholder="Choose Category"
              label="name"
              :options="cgCategoryOptions"
            />
          </b-form-group>
        </b-col>
        <b-col md="4">
          <b-form-group>
            <label>First Ministry</label>
            <v-select
              v-model="firstMinistry"
              placeholder="Choose Ministry"
              label="name"
              :options="firstMinistryOptions"
            />
          </b-form-group>
        </b-col>
        <b-col md="4">
          <b-form-group>
            <label>Second Ministry</label>
            <v-select
              v-model="secondMinistry"
              placeholder="Choose Ministry"
              label="name"
              :options="secondMinistryOptions"
            />
          </b-form-group>
        </b-col>
        <b-col md="4">
          <b-form-group>
            <label>Status</label>
            <v-select
              v-model="status"
              placeholder="Choose Status"
              label="name"
              :options="statusOptions"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col md="4">
          <b-form-group>
            <b-button
              variant="primary"
              class="mr-1"
              type="button"
              @click="searchData($route.params.id, divisions)"
            >
              Search
            </b-button>
            <vue-excel-xlsx
              :data="exportData"
              :columns="exportColumns"
              :file-name="'servolution'"
              :file-type="'xlsx'"
              :sheet-name="'sheetname'"
              class="btn btn-primary"
            >
              Export
            </vue-excel-xlsx>
          </b-form-group>
        </b-col>
      </b-row>
    </div>
    <!-- <div class="custom-search d-flex justify-content-end">
      <b-form-group>
        <div class="d-flex align-items-center">
          <label class="mr-1">Search</label>
          <b-form-input
            v-model="searchTerm"
            placeholder="Search"
            type="text"
            class="d-inline-block"
          />
        </div>
      </b-form-group>
    </div> -->

    <vue-good-table
      :columns="columns"
      :rows="rows"
      style-class="vgt-table striped"
      :pagination-options="{
        enabled: true,
        perPageDropdownEnabled: false,
        dropdownAllowAll: false,
      }"
    >
      <template
        slot="table-row"
        slot-scope="props"
      >
        <span
          v-if="props.column.field == 'name'"
          class="text-nowrap"
        >
          <b-avatar
            :src="props.row.photo"
            class="mx-1"
          />
          <span class="text-nowrap">{{ props.row.name }}</span>
        </span>
        <span
          v-else-if="props.column.field == 'first_option_audition_schedule'"
        >
          <span
            v-if="(props.row.first_option_audition_room && props.row.first_option_audition_schedule) && (props.row.first_option_interview_room && props.row.first_option_interview_schedule)"
          >
            <li>Audisi: {{ moment(props.row.first_option_audition_schedule).format("dddd, DD MMMM YYYY HH:mm") }} ({{ props.row.first_option_audition_room }})</li>
            <li>Interview: {{ moment(props.row.first_option_interview_schedule).format("dddd, DD MMMM YYYY HH:mm") }} ({{ props.row.first_option_interview_room }})</li>
          </span>
          <span
            v-else-if="(props.row.first_option_audition_room && props.row.first_option_audition_schedule) && ((props.row.first_option_interview_room && !props.row.first_option_interview_schedule) || (!props.row.first_option_interview_room && props.row.first_option_interview_schedule) || (!props.row.first_option_interview_room && !props.row.first_option_interview_schedule))"
          >
            <li>Audisi: {{ moment(props.row.first_option_audition_schedule).format("dddd, DD MMMM YYYY HH:mm") }} ({{ props.row.first_option_audition_room }})</li>
            <li>Interview: Dihubungi PIC</li>
          </span>
          <span
            v-else-if="((props.row.first_option_audition_room && !props.row.first_option_audition_schedule) || (!props.row.first_option_audition_room && props.row.first_option_audition_schedule) || (!props.row.first_option_audition_room && !props.row.first_option_audition_schedule)) && (props.row.first_option_interview_room && props.row.first_option_interview_schedule && props.row.division_type === 2)"
          >
            {{ moment(props.row.first_option_interview_schedule).format("dddd, DD MMMM YYYY HH:mm") }} ({{ props.row.first_option_interview_room }})
          </span>
          <span
            v-else-if="((props.row.first_option_audition_room && !props.row.first_option_audition_schedule) || (!props.row.first_option_audition_room && props.row.first_option_audition_schedule) || (!props.row.first_option_audition_room && !props.row.first_option_audition_schedule)) && (props.row.first_option_interview_room && props.row.first_option_interview_schedule)"
          >
            <li>Audisi: Dihubungi PIC</li>
            <li>Interview: {{ moment(props.row.first_option_interview_schedule).format("dddd, DD MMMM YYYY HH:mm") }} ({{ props.row.first_option_interview_room }})</li>
          </span>
          <span v-else>
            <li>Audisi: Dihubungi PIC</li>
            <li>Interview: Dihubungi PIC</li>
          </span>
        </span>
        <span
          v-else-if="props.column.field == 'second_option_interview_schedule' && props.row.second_option_ministry"
        >
          <span
            v-if="props.row.second_option_interview_room && props.row.second_option_interview_schedule"
          >
            {{ moment(props.row.second_option_interview_schedule).format("dddd, DD MMMM YYYY HH:mm") }} ({{ props.row.second_option_interview_room }})
          </span>
          <span
            v-else-if="!props.row.second_option_interview_room || !props.row.second_option_interview_schedule"
          >
            Dihubungi PIC
          </span>
        </span>
        <span
          v-else-if="props.column.field == 'second_option_interview_schedule' && !props.row.second_option_ministry"
        >
          -
        </span>
        <span v-else-if="props.column.field == 'action'">
          <b-button
            v-if="props.row.servolution_registration_status_id === 1 || props.row.servolution_registration_status_id === 2 || props.row.servolution_registration_status_id === 12 || props.row.servolution_registration_status_id === 14"
            v-b-tooltip.hover.bottom="'Edit'"
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="warning"
            class="btn-icon"
            style="margin-right: 2px; margin-bottom: 2px;"
            :to="{ name: 'edit-servolution-participant', params: { id: props.row.id } }"
          >
            <feather-icon icon="Edit2Icon" />
          </b-button>
          <b-button
            v-if="(!props.row.second_option_ministry && props.row.servolution_registration_status_id === 5) || props.row.servolution_registration_status_id === 7 || props.row.servolution_registration_status_id === 8 || props.row.servolution_registration_status_id === 3 || props.row.servolution_registration_status_id === 13 || props.row.servolution_registration_status_id === 69"
            v-b-tooltip.hover.bottom="'View'"
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="warning"
            class="btn-icon"
            style="margin-right: 2px; margin-bottom: 2px;"
            :to="{ name: 'servolution-assessment', params: { id: props.row.id } }"
          >
            <feather-icon icon="EyeIcon" />
          </b-button>
          <b-button
            v-if="props.row.servolution_registration_status_id === 2 || props.row.servolution_registration_status_id === 4 || props.row.servolution_registration_status_id === 6 || props.row.servolution_registration_status_id === 67 || props.row.servolution_registration_status_id === 34"
            v-b-tooltip.hover.bottom="'Assessment'"
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="success"
            class="btn-icon"
            style="margin-right: 2px; margin-bottom: 2px;"
            :to="{ name: 'servolution-assessment', params: { id: props.row.id } }"
          >
            <feather-icon icon="ClipboardIcon" />
          </b-button>
          <b-button
            v-if="props.row.servolution_registration_status_id === 1 || props.row.servolution_registration_status_id === 2 || props.row.servolution_registration_status_id === 12 || props.row.servolution_registration_status_id === 14"
            v-b-tooltip.hover.bottom="'Delete'"
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="danger"
            class="btn-icon"
            style="margin-right: 2px; margin-bottom: 2px;"
            @click="deleteParticipant(props.row.id)"
          >
            <feather-icon icon="TrashIcon" />
          </b-button>
        </span>
        <span v-else-if="props.column.field == 'second_option_ministry'">
          {{ props.row.second_option_ministry ? props.row.second_option_ministry : '-' }}
        </span>
        <span v-else-if="props.column.field == 'status'">
          <b-badge
            :variant="props.row.status_style"
          >
            {{ props.row.status_name }}
          </b-badge>
        </span>
        <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>
    </vue-good-table>
  </b-card>
</template>

<script>
import {
  BAvatar,
  BFormGroup,
  BFormInput,
  BCard,
  BButton,
  VBTooltip,
  BBadge,
  BRow,
  BCol,
} from 'bootstrap-vue'
import Loading from 'vue-loading-overlay'
import Ripple from 'vue-ripple-directive'
import CryptoJS from 'crypto-js'
import * as moment from 'moment'
// import excel from 'vue-excel-export'
import _ from 'lodash'
import vSelect from 'vue-select'
import 'vue-loading-overlay/dist/vue-loading.css'
import { VueGoodTable } from 'vue-good-table'
import { isUserLoggedIn } from '@/auth/utils'

export default {
  components: {
    BAvatar,
    BFormGroup,
    BFormInput,
    BCard,
    VueGoodTable,
    BButton,
    BBadge,
    // BDropdown,
    // BDropdownItem,
    Loading,
    // excel,
    vSelect,
    BRow,
    BCol,
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  data() {
    return {
      moment,
      isLoading: true,
      fullPage: false,
      pageLength: 10,
      dir: false,
      nij: '',
      name: '',
      gender: '',
      genderOptions: [],
      cgCategory: '',
      cgCategoryOptions: [],
      firstMinistry: '',
      firstMinistryOptions: [],
      secondMinistry: '',
      secondMinistryOptions: [],
      status: '',
      statusOptions: [],
      columns: [
        {
          label: 'Name',
          field: 'name',
        },
        {
          label: 'NIJ',
          field: 'nij',
        },
        {
          label: 'Age',
          field: 'age',
        },
        {
          label: 'Gender',
          field: 'gender',
        },
        {
          label: 'CG Category',
          field: 'connect_group_category',
        },
        {
          label: 'First Ministry',
          field: 'first_option_ministry',
        },
        {
          label: 'First Ministry Schedule',
          field: 'first_option_audition_schedule',
        },
        {
          label: 'Second Ministry',
          field: 'second_option_ministry',
        },
        {
          label: 'Second Ministry Schedule',
          field: 'second_option_interview_schedule',
        },
        {
          label: 'Status',
          field: 'status',
        },
        {
          label: 'Action',
          field: 'action',
        },
      ],
      rows: [],
      searchTerm: '',
      json_fields: {
        'Complete name': 'name',
        City: 'city',
        Telephone: 'phone.mobile',
        'Telephone 2': {
          field: 'phone.landline',
          callback: value => `Landline Phone - ${value}`,
        },
      },
      exportColumns: [
        {
          label: 'NIJ',
          field: 'nij',
        },
        {
          label: 'Name',
          field: 'name',
        },
        {
          label: 'Photo',
          field: 'photo',
        },
        {
          label: 'Age',
          field: 'age',
        },
        {
          label: 'Gender',
          field: 'gender',
        },
        {
          label: 'Email',
          field: 'email',
        },
        {
          label: 'Phone',
          field: 'phone',
        },
        {
          label: 'CG Category',
          field: 'connect_group_category',
        },
        {
          label: 'CG',
          field: 'connect_group_name',
        },
        {
          label: 'CG Level',
          field: 'connect_group_level',
        },
        {
          label: 'GMS',
          field: 'church_name',
        },
        {
          label: 'Attending Service at',
          field: 'service_category',
        },
        {
          label: 'First Division',
          field: 'first_option_ministry',
        },
        {
          label: 'First Option Audition Room',
          field: 'first_option_audition_room',
          dataFormat: this.formatRoom,
        },
        {
          label: 'First Option Audition Schedule',
          field: 'first_option_audition_schedule',
          dataFormat: this.formatSchedule,
        },
        {
          label: 'First Option Interview Room',
          field: 'first_option_interview_room',
          dataFormat: this.formatRoom,
        },
        {
          label: 'First Option Interview Schedule',
          field: 'first_option_interview_schedule',
          dataFormat: this.formatSchedule,
        },
        {
          label: 'Second Division',
          field: 'second_option_ministry',
        },
        {
          label: 'Second Option Interview Room',
          field: 'second_option_interview_room',
          dataFormat: this.formatRoom,
        },
        {
          label: 'Second Option Interview Schedule',
          field: 'second_option_interview_schedule',
          dataFormat: this.formatSchedule,
        },
        {
          label: 'Status',
          field: 'status_name',
        },
      ],
      exportData: [],
      divisions: [],
    }
  },
  mounted() {
    // Set data
    const userData = JSON.parse(CryptoJS.AES.decrypt(sessionStorage.getItem('userData'), process.env.VUE_APP_COOKIE_SECRET).toString(CryptoJS.enc.Utf8))
    const divisions = userData[0].divisions.map(elem => elem).join(',')
    this.divisions = divisions

    // Load
    this.loadDefaultRegistrationData(this.$route.params.id, divisions)
  },
  methods: {
    loadDefaultRegistrationData(eventId, divisions) {
      // Get the data
      this.$http.get(`${process.env.VUE_APP_API_BASE_URL}/api/servolution/registrations/${eventId}/${divisions}`)
        .then(response => {
          this.isLoading = false
          this.rows = response.data.filter(data => (data.is_holy_spirit_baptized || _.isNull(data.is_holy_spirit_baptized)) && _.isNull(data.deleted_at))
          this.exportData = response.data.filter(data => (data.is_holy_spirit_baptized || _.isNull(data.is_holy_spirit_baptized)) && _.isNull(data.deleted_at))

          // Load search default
          this.loadSearchDefaultValues(response.data)
      })
    },
    loadSearchDefaultValues(lists) {
      // Gender
      const genders = _.sortBy(_.uniqBy(lists, 'gender'), ['gender'])
      _.each(genders, data => {
        this.genderOptions.push({ name: data.gender })
      })

      // Connect Group Category
      const categories = _.sortBy(_.uniqBy(lists, 'connect_group_category'), ['connect_group_category'])
      _.each(categories, data => {
        this.cgCategoryOptions.push({ name: data.connect_group_category })
      })

      // First Ministry
      const firstMinistries = _.sortBy(_.uniqBy(lists, 'first_option_ministry'), ['first_option_ministry'])
      _.each(firstMinistries, data => {
        this.firstMinistryOptions.push({ id: data.first_option_ministry_id, name: data.first_option_ministry })
      })

      // Second Ministry
      const secondMinistries = _.sortBy(_.uniqBy(lists, 'second_option_ministry'), ['second_option_ministry'])
      _.each(secondMinistries, data => {
        if (!_.isNull(data.second_option_ministry)) {
          this.secondMinistryOptions.push({ id: data.second_option_ministry_id, name: data.second_option_ministry })
        }
      })

      // Status
      const statuses = _.sortBy(_.uniqBy(lists, 'status_name'), ['status_name'])
      _.each(statuses, data => {
        this.statusOptions.push({ id: data.servolution_registration_status_id, name: data.status_name })
      })
    },
    searchData(eventId, divisions) {
      // Set search params
      const gender = this.gender ? this.gender.name : ''
      const cgCategory = this.cgCategory ? this.cgCategory.name : ''
      const firstMinistry = this.firstMinistry ? this.firstMinistry.id : ''
      const secondMinistry = this.secondMinistry ? this.secondMinistry.id : ''
      const status = this.status ? this.status.id : 0

      // Get the data
      this.isLoading = true
      this.$http.get(`${process.env.VUE_APP_API_BASE_URL}/api/servolution/registrations/search/${eventId}/${divisions}?nij=${this.nij}&name=${this.name}&gender=${gender}&cgcategory=${cgCategory}&first_ministry=${firstMinistry}&second_ministry=${secondMinistry}&status=${status}`)
        .then(response => {
          this.isLoading = false
          this.rows = response.data
          this.exportData = response.data
      })
    },
    priceFormat(value) {
      return `$ ${value}`
    },
    exportParticipants() {
      
    },
    formatRoom(value) {
      if (value) {
        return value
      }

      return '-'
    },
    formatSchedule(value) {
      if (value) {
        return moment(value).format('dddd, DD MMMM YYYY HH:mm')
      }

      return '-'
    },
    deleteParticipant(id) {
      // Check for cookies before submit
      // Get logged in status
      const isLoggedIn = isUserLoggedIn()
      // If not logged in, then redirect to login page
      if (!isLoggedIn) {
        return this.$router.replace('/login')
      }

      // Check if the user has access
      if (!this.$can('delete', 'servolution_participants')) {
        this.$swal({
          icon: 'error',
          title: 'Forbidden!',
          text: 'You are not authorized to do this action.',
          customClass: {
            confirmButton: 'btn btn-success',
          },
        })
      } else {
        this.$swal({
          title: 'Are you sure?',
          text: "You won't be able to revert this!",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Yes, delete it!',
          customClass: {
            confirmButton: 'btn btn-primary',
            cancelButton: 'btn btn-outline-danger ml-1',
          },
          buttonsStyling: false,
        }).then(result => {
          // Show loading
          this.isLoading = true

          if (result.value) {
            // Delete participant
            this.$http.patch(`${process.env.VUE_APP_API_BASE_URL}/api/servolution/registration/${id}`, { updatedAt: new Date(), deletedAt: new Date() })
              .then(() => {
              // Hide loading
              this.isLoading = false

              this.$swal({
                icon: 'success',
                title: 'Deleted!',
                text: 'This participant has been deleted.',
                customClass: {
                  confirmButton: 'btn btn-success',
                },
              })

              const participantIndex = this.rows.findIndex(participant => participant.id === id)
              this.rows.splice(participantIndex, 1)
            })
          } else {
            // Hide loading
            this.isLoading = false
          }
        })
      }
    },
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-good-table.scss';
table.vgt-table {
  border: none;
  font-size: 1rem;
}
.vgt-table thead th {
  // border-bottom: 2px solid #ebe9f1;
  outline: none;
  background-color: #f3f2f7;
}
table.vgt-table td {
  vertical-align: middle !important;
}
.vgt-wrap__footer {
  border: none;
}
</style>